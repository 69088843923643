import { useCallback, useEffect, useState } from 'react';
import { SetFieldValue } from '@mantine/form/lib/types';
import { usePrivateDocument } from 'hooks';
import { CorporateOrderPayload } from 'shared';

export const useUploadInvoice = ({
  setInvoice,
}: {
  setInvoice: SetFieldValue<CorporateOrderPayload>;
}) => {
  const [file, setFile] = useState<File | null>(null);

  const { getDocument, upsertAsync, isUpserting, isLoading, document } =
    usePrivateDocument();

  const getUrlDocument = useCallback(
    async (file: File) => {
      await getDocument(file.name);
    },
    [getDocument],
  );

  const upsertFile = useCallback(
    async (file: File) => {
      const { url } = await upsertAsync({
        document: file,
        url: document?.url ?? '',
      });
      setInvoice('invoice_file', url);
    },
    [upsertAsync, document, setInvoice],
  );

  useEffect(() => {
    if (file) {
      getUrlDocument(file);
    }
  }, [file, getUrlDocument]);

  useEffect(() => {
    if (document && file) {
      upsertFile(file);
    }
  }, [document, file, upsertFile]);

  return { setFile, isLoading: isUpserting || isLoading };
};
