import {
  CreateDiscount,
  DATE_FORMAT_DOT,
  DiscountDto,
  formatDate,
} from 'shared';

export const getInitialValues = (
  discount?: DiscountDto,
): CreateDiscount | undefined => {
  if (!discount) return;

  const {
    unit,
    name,
    value,
    comment,
    category,
    club_tier,
    locations,
    end_date,
    is_active,
    start_date,
    description,
    minimum_total,
    free_shipping,
    inventory_type,
    minimum_quantity,
    applied_to_glass,
    one_time_promotion,
    hide_from_ecommerce,
  } = discount;

  return {
    unit,
    name,
    value,
    comment,
    is_active,
    description,
    minimum_total,
    free_shipping,
    inventory_type,
    minimum_quantity,
    applied_to_glass,
    one_time_promotion,
    hide_from_ecommerce,
    start_date: formatDate(start_date, DATE_FORMAT_DOT),
    end_date: formatDate(end_date, DATE_FORMAT_DOT),
    subcategory_id: category?.id ?? null,
    category_id: category?.parent?.id ?? null,
    club_tier_id: club_tier?.id ?? null,
    location_ids: locations?.map(({ id }) => id) ?? null,
  };
};
