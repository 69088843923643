import { Button, FileButton, Flex, Grid } from '@mantine/core';
import { SetFieldValue } from '@mantine/form/lib/types';
import { IconUpload } from '@tabler/icons-react';
import { useUploadInvoice } from 'pages/orders/corporateOrders/hooks';
import { CorporateOrderPayload } from 'shared';

type Props = {
  invoiceFile: string;
  setInvoice: SetFieldValue<CorporateOrderPayload>;
};

export const InvoiceButtons = ({ invoiceFile, setInvoice }: Props) => {
  const { setFile, isLoading } = useUploadInvoice({ setInvoice });

  return (
    <Grid.Col span={4} mt={28}>
      <Flex justify="flex-end" gap={2}>
        <FileButton onChange={setFile}>
          {(props) => (
            <Button
              {...props}
              variant="white"
              loading={isLoading}
              leftIcon={<IconUpload size={14} />}
            >
              Upload Invoice
            </Button>
          )}
        </FileButton>

        <Button
          component="a"
          variant="white"
          target="_blank"
          disabled={!invoiceFile}
          href={invoiceFile ?? ''}
        >
          Preview Invoice
        </Button>
      </Flex>
    </Grid.Col>
  );
};
