import { Link, useNavigate } from 'react-router-dom';
import { BackButton, ContentLayout } from 'components';
import { useEditCorporateOrder } from '../../hooks';
import { CreateForm, EditForm, StatusBasedForm } from '../form';
import { Promocode } from '../promocode';
import { useApplyPromocode } from '../../hooks/useApplyPromocode';
import { OrderPaymentStatus, OrderStatus } from 'shared';

export const Edit = ({ rootPath }: { rootPath: string }) => {
  const navigate = useNavigate();
  const {
    status,
    number,
    initialStep,
    initialValues,
    userCards,
    paymentByCheck,
    threeDSecureUrl,
    orderTotalPrice,
    summary,
    errors,
    promocodeAppliedMessage,
    defaultClubMembership,
    defaultCustomer,
    defaultPromocode,
    isLoading,
    isDeleting,
    isUpdating,
    isPaying,
    isRefunding,
    isProcessingApplied,
    refetchOrder,
    handleDelete,
    handleRefund,
    handleUpdateOrder,
    handleConfirmAndPay,
    setPaymentByCheckData,
  } = useEditCorporateOrder();

  const { id, payment_status } = initialValues ?? {};
  const { isApplied, setPromocodeId, handleApplyPromocode } = useApplyPromocode(
    { orderId: id, refetchOrder },
  );

  const title = `Order ${number}`;

  const disabledPromocode =
    payment_status === OrderPaymentStatus.PAID || status === OrderStatus.CANCEL;

  return (
    <ContentLayout
      title={title}
      isLoading={isLoading}
      processing={isUpdating}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
    >
      <StatusBasedForm
        status={status}
        editDraftOrder={
          <CreateForm
            isEdit
            errors={errors}
            rootPath={rootPath}
            initialStep={initialStep}
            initialValues={initialValues}
            defaultCustomer={defaultCustomer}
            isDeleting={isDeleting}
            handleDelete={handleDelete}
            handleRefetchOrder={() => refetchOrder()}
          />
        }
        editCreatedOrder={
          <EditForm
            rootPath={rootPath}
            userCards={userCards}
            threeDSecureUrl={threeDSecureUrl}
            orderTotalPrice={orderTotalPrice}
            initialValues={initialValues}
            paymentByCheck={paymentByCheck}
            summary={summary}
            defaultClubMemberShip={defaultClubMembership}
            isProcessingApplied={isProcessingApplied}
            isPaying={isPaying}
            isRefunding={isRefunding}
            isDeleting={isDeleting}
            setPaymentByCheckData={setPaymentByCheckData}
            handleDelete={handleDelete}
            handleRefund={handleRefund}
            handleUpdateOrder={handleUpdateOrder}
            handleConfirmAndPay={handleConfirmAndPay}
            promocode={
              <Promocode
                isApplied={isApplied}
                disabledPromocode={disabledPromocode}
                isProcessingApplied={isProcessingApplied}
                defaultPromocode={defaultPromocode}
                promocodeAppliedMessage={promocodeAppliedMessage}
                setPromocodeId={setPromocodeId}
                handleApplyPromocode={handleApplyPromocode}
              />
            }
          />
        }
      />
    </ContentLayout>
  );
};
